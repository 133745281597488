import { inject, Injectable } from '@angular/core';

//Websocket Pusher
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { Channel, PresenceChannel } from 'laravel-echo/dist/channel';
import { NEVER, Observable, of, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { CmmApiService } from './helpers/cmm-api.service';
import { AuthService } from './auth.service';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

type SocketEventName =
  | 'notification.created'
  | 'notification.updated'
  | 'notification.deleted'
  | 'code-workspace.building'
  | 'code-workspace.built'
  | 'code-workspace.build-error'
  | 'code-workspace.deployed'
  | 'code-workspace.deploy-error';

export type SocketEventData<T = any> = {
  type: SocketEventName;
  data: T;
  users?: never;
};

export type SocketUserData = {
  type: 'here' | 'joining' | 'leaving';
  users: PresenceUser[];
  data?: never;
};

export type PresenceUser = {
  id: number;
  name: string;
};

type ChannelArray = {
  [key: string]: {
    listener: Subject<SocketEventData | SocketUserData>;
    observer: Observable<SocketEventData | SocketUserData>;
    channel: Channel;
    events: SocketEventName[];
  };
};

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private echo: Echo | null = null;
  private channels: ChannelArray = {};

  private envName = '';

  private isAuthenticated$!: Observable<boolean>;

  private authService = inject(AuthService);

  constructor() {
    this.setupEcho();
  }

  private setupEcho() {
    this.isAuthenticated$ = this.authService.isAuthenticated$.pipe(
      tap(() => {
        (window as any).Pusher = Pusher;

        const echoSettings: any = {
          broadcaster: 'pusher',
          enabledTransports: ['ws', 'wss'],
          key: environment.websockets.key,
          cluster: 'cmm1',
          wsHost: environment.websockets.url,
          wssHost: environment.websockets.url,
          wsPort: 6001,
          wssPort: 6001,
          //wsPath: 'api',
          forceTLS: true,
          disableStats: true,
          encrypted: true,
          authorizer: (channel: any, options: any) => {
            return {
              authorize: (socketId: string, callback: any) => {
                fetch(`${CmmApiService.API_URL}/broadcasting/auth`, {
                  method: 'POST',
                  body: JSON.stringify({
                    socket_id: socketId,
                    channel_name: channel.name,
                  }),
                  headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                  },
                  credentials: 'include',
                })
                  .then((response) => {
                    return response.json();
                  })
                  .then((response) => {
                    callback(null, response);
                  })
                  .catch((error) => {
                    callback(error);
                  });
              },
            };
          },
        };

        this.echo = (window as any).Echo = new Echo(echoSettings);
      }),
      map((_) => true),
      shareReplay(1),
    );

    this.isAuthenticated$.subscribe();
  }

  /**
   * Once a channel has been first listened to, the events cannot be changed
   * @param channelName Name of the channel to connect to
   * @param eventNames Array of events to listen to
   * @returns Observable of the channel listener with event data
   */
  public listen<T = any>(
    channelName: string,
    eventNames: SocketEventName[],
    channelType: 'private' | 'presence' = 'private',
  ): Observable<SocketEventData<T> | SocketUserData> {
    return this.isAuthenticated$.pipe(
      switchMap((_) => {
        if (!this.echo) return NEVER; // an error occurred

        let obs: Observable<SocketEventData<T> | SocketUserData> | null = null;

        if (!this.channels[channelName]) {
          let channel: Channel | PresenceChannel | null = null;

          if (channelType === 'private') {
            channel = this.echo.private(channelName);
          } else if (channelType === 'presence') {
            channel = this.echo.join(channelName);
          }

          if (!channel) return NEVER; // an error occurred

          const subject = new Subject<SocketEventData<T> | SocketUserData>();
          if (channelType === 'presence') {
            (channel as PresenceChannel)
              .here((users: PresenceUser[]) => {
                subject.next({
                  type: 'here',
                  users: users,
                });
              })
              .joining((user: PresenceUser) => {
                subject.next({
                  type: 'joining',
                  users: [user],
                });
              })
              .leaving((user: PresenceUser) => {
                subject.next({
                  type: 'leaving',
                  users: [user],
                });
              });
          }

          eventNames.forEach((event) => {
            channel.listen(`.${event}`, (data: T) => {
              subject.next({
                type: event,
                data: data,
              });
            });
          });

          obs = subject.asObservable();

          this.channels[channelName] = {
            listener: subject,
            observer: obs,
            channel: channel,
            events: eventNames,
          };
        } else {
          obs = this.channels[channelName].observer;
        }

        return obs;
      }),
    );
  }

  /**
   * Leaves the channel and sends a completed status to subscribers
   *
   * @param channelName
   * @returns
   */
  public leave(channelName: string) {
    if (!this.echo) return;

    this.channels[channelName]?.listener.complete();
    delete this.channels[channelName];

    this.echo.leave(channelName);
  }
}
